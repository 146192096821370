import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { SystemCondition, SystemFilter, SystemsConnection } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import { UseQueryResult } from 'react-query/types/react/types';
import { GroupField, groupToExpr, SortField, sortToExpr } from '@hooks/systems/constants';
import { DeepPartial } from 'redux';
import { SystemWithStats } from '@hooks/systems/types';
import { useSystemsStats } from '@hooks/systems/useSystemsStats';

export type SystemsGroup = {
  // Value of grouping field
  label: string;
  // Single page of systems according to given pagination
  systems: SystemWithStats[];
  // Ids of all systems in the group disregarding pagination
  ids: number[];
};

export type SystemsGroups = {
  // filtered and paginated groups
  groups: SystemsGroup[];
  // filtered and NON-paginated ids
  allIds: number[];
};

export type Result = Pick<UseQueryResult<SystemsGroups, Error>, 'data' | 'isLoading' | 'isError' | 'error'>;

export const useSystemsGroups = (args: {
  condition?: DeepPartial<SystemCondition>;
  filter?: DeepPartial<SystemFilter>;
  groupBy?: GroupField;
  orderBy?: [field: SortField, desc: boolean][];
  first?: number;
  offset?: number;
}): Result => {
  const { condition, filter, groupBy, orderBy, first = 10, offset = 0 } = args;

  const {
    data: idsGroups,
    isSuccess: isIdsSuccess,
    isLoading: isIdsLoading,
    isError: isIdsError,
    error: idsError
  } = useQuery<{ systemsConnection: SystemsConnection }, Error>(
    [ReactQueryKey.System, 'useSystemsGroups_ids', args],
    async () => {
      try {
        return await postGraphql<{ systemsConnection: SystemsConnection }>(
          gql`
            query SYSTEM_GROUPS_QUERY(
              $condition: SystemCondition
              $filter: SystemFilter
              $groupBy: [SystemGroupBy!]!
              $orderBy: [SystemsOrderBy!]!
            ) {
              systemsConnection: systemsConnection(condition: $condition, filter: $filter, orderBy: $orderBy) {
                groupedAggregates(groupBy: $groupBy) {
                  keys
                  arrayAgg {
                    id(orderBy: $orderBy)
                  }
                }
              }
            }
          `,
          {
            condition,
            filter,
            groupBy: groupToExpr(groupBy),
            orderBy: sortToExpr(orderBy)
          }
        );
      } catch (e) {
        throw apiErrorHandler('Error fetching systems', e);
      }
    },
    {
      keepPreviousData: true
    }
  );

  const paginatedGroupsIds = (idsGroups?.systemsConnection?.groupedAggregates || []).reduce(
    (acc, group) => {
      acc[group.keys[0] || null] = (group.arrayAgg.id || []).slice(offset, offset + first);

      return acc;
    },
    {} as { [group: string | null]: number[] }
  );

  const allPaginatedGroupsIds = uniq(Object.values(paginatedGroupsIds).flat());

  const {
    data: systems,
    isLoading: isPagesLoading,
    isError: isPagesError,
    error: pagesError
  } = useQuery<{ systemsConnection: SystemsConnection }, Error>(
    [ReactQueryKey.System, 'useSystemsGroups_pages', idsGroups],
    async () => {
      try {
        return await postGraphql<{ systemsConnection: SystemsConnection }>(
          gql`
            query SYSTEMS_BY_IDS_QUERY($ids: [Int!]!) {
              systemsConnection(filter: { id: { in: $ids } }) {
                nodes {
                  id
                  createdAt
                  monitored
                  raw
                  providerId
                  status
                  operationalAt
                  address
                  addressCity
                  addressState
                  addressStreet
                  addressZip
                  lastReportAt
                  connectionType
                  size
                  providerStatus
                  name
                  number
                  clientType
                  uuid
                  notes
                  installedAt
                  operationStatus

                  integration {
                    id
                    provider
                  }

                  project {
                    id
                  }

                  profile {
                    id
                    name
                  }
                }
              }
            }
          `,
          {
            ids: allPaginatedGroupsIds
          }
        );
      } catch (e) {
        throw apiErrorHandler('Error fetching systems', e);
      }
    },
    {
      keepPreviousData: true,
      enabled: isIdsSuccess
    }
  );

  const {
    data: systemsStats,
    isLoading: isStatsLoading,
    isError: isStatsError,
    error: statsError
  } = useSystemsStats(systems?.systemsConnection?.nodes.map(({ id }) => id) || []);

  const systemsMap = useMemo(
    () =>
      (systems?.systemsConnection?.nodes || []).reduce(
        (acc, system) => {
          acc[system.id] = {
            ...system,
            productionYesterday: systemsStats.productionYesterday[system.uuid],
            productionWeek: systemsStats.productionWeek[system.uuid],
            productionMonth: systemsStats.productionMonth[system.uuid],
            productionYear: systemsStats.productionYear[system.uuid],
            productionLifetime: systemsStats.productionLifetime[system.uuid],
            consumptionYesterday: systemsStats.consumptionYesterday[system.uuid],
            consumptionWeek: systemsStats.consumptionWeek[system.uuid],
            consumptionMonth: systemsStats.consumptionMonth[system.uuid],
            consumptionYear: systemsStats.consumptionYear[system.uuid],
            consumptionLifetime: systemsStats.consumptionLifetime[system.uuid],
            peakPower: systemsStats.peakPower[system.uuid]
          } as SystemWithStats;

          return acc;
        },
        {} as { [id: number]: SystemWithStats }
      ),
    [systems, systemsStats]
  );

  const groups: SystemsGroup[] = useMemo(
    () =>
      (idsGroups?.systemsConnection?.groupedAggregates || []).map((group) => ({
        label: group.keys[0] || null,
        ids: group.arrayAgg.id || [],
        systems: paginatedGroupsIds[group.keys[0] || null].map((id) => systemsMap[id])
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [systemsMap]
  );

  const allGroupsIds = useMemo(
    () => uniq((idsGroups?.systemsConnection?.groupedAggregates || []).flatMap((group) => group.arrayAgg.id)),
    [idsGroups]
  );

  const result: SystemsGroups = {
    groups,
    allIds: allGroupsIds
  };

  return {
    isLoading: isIdsLoading || isPagesLoading || isStatsLoading,
    isError: isIdsError || isPagesError || isStatsError,
    error: idsError || pagesError || statsError,
    data: result
  };
};
